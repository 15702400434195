<template>
<div id="app" class="plus home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="ar" page="plus" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #1538b4 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <!-- 首图logo -->
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/blog6-logo-3.webp" alt="GB WhatsApp APK" class="wp-image-615">
                      </figure>
                    </div>
                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      واتساب بلس
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        الإصدار: <strong>18.20</strong> | الحجم: <strong>77 ميجابايت</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      تم تطوير واتساب بلس بناءً على تطبيق واتساب الرسمي الذي يستخدمه أكثر من ملياري شخص حول العالم. كواحد من تعديلات واتساب، يتيح للمستخدمين قراءة الرسائل المحذوفة، وإخفاء علامتي الصح، وتغيير السمات والخطوط، وإعداد رسائل الرد التلقائي والمزيد من الميزات القابلة للتخصيص.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <router-link class="wp-block-button__link wp-element-button" :to="{name:`${selectedLanguage}-download`}">
                          تحميل واتساب بلس
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                <div id="beesads"></div>

                <!-- جدول المحتويات -->
                <div id="toc_container" class="no_bullets">
                  <section class="table-content" v-show="!tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      جدول المحتويات
                      <img src="../assets/minus.svg" alt="ناقص">
                    </div>

                    <ul class="toc_list">
                      <li><a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> تفاصيل تطبيق واتساب بلس</a></li>
                      <li><a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> لقطات شاشة</a></li>
                      <li><a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> الحاجة إلى واتساب بلس</a></li>
                      <li><a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> ميزات واتساب بلس</a></li>
                      <li><a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> الميزات الجديدة المضافة في 2024</a></li>
                      <li><a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> واتساب بلس مقابل واتساب الرسمي</a></li>
                      <li><a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span> النسخ الاحتياطي لواتساب بلس</a></li>
                      <li><a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> متطلبات تطبيق واتساب بلس</a></li>
                      <li><a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> كيفية تحميل واتساب بلس؟</a></li>
                      <li><a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> كيفية تحديث واتساب بلس؟</a></li>
                      <li><a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> الإصدار الرسمي 18.20 من واتساب بلس</a></li>
                      <li><a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span> الإصدار القادم 2025</a></li>
                      <li><a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> الإصدارات القديمة من واتساب بلس</a></li>
                      <li><a href="#TBC_13"><span class="toc_number toc_depth_12">14.</span> واتساب بلس لأجهزة آيفون</a></li>
                      <li><a href="#TBC_14"><span class="toc_number toc_depth_12">15.</span> تحميل واتساب بلس للكمبيوتر/اللابتوب</a></li>
                      <li><a href="#TBC_15"><span class="toc_number toc_depth_12">16.</span> إيجابيات وسلبيات واتساب بلس</a></li>
                      <li><a href="#TBC_16"><span class="toc_number toc_depth_12">17.</span> آراء المستخدمين</a></li>
                      <li><a href="#TBC_17"><span class="toc_number toc_depth_12">18.</span> الأسئلة الشائعة</a></li>
                      <li><a href="#TBC_18"><span class="toc_number toc_depth_12">19.</span> الكلمات النهائية</a></li>
                    </ul>
                  </section>

                  <section class="table-content hide" v-show="tableHide">
                    <div class="table-content-title" @click="tableHide = !tableHide">
                      جدول المحتويات
                      <img src="../assets/plus.svg" alt="زائد">
                    </div>
                  </section>
                </div>

                <h2 class="h">
                  <span id="TBC_0">تفاصيل تطبيق واتساب بلس</span>
                </h2>
                <ul>
                  <li><strong>الحجم: </strong>77 ميجابايت</li>
                  <li><strong>عدد المستخدمين: </strong>2 مليون مستخدم حول العالم</li>
                  <li><strong>تكرار التحديثات: </strong>تحديثات منتظمة كل بضعة أشهر</li>
                  <li><strong>المطورون: </strong>مطورون من المجتمع</li>
                  <li><strong>توفر التطبيق: </strong>لنظام أندرويد فقط، غير متوفر على متجر Google Play</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_1">لقطة شاشة</span>
                </h2>

                <div class="wp-block-image">
                  <figure class="aligncenter size-full">
                    <picture>
                      <source srcset="../assets/plus-1.webp" type="image/webp">
                      <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/plus-1.webp" alt="واجهة واتساب بلس" class="wp-image-48">
                    </picture>
                  </figure>
                </div>

                <h2 class="h">
                  <span id="TBC_2">أهمية واتساب بلس</span>
                </h2>

                <p>
                  يُستخدم واتساب بلس على نطاق واسع من قبل المستخدمين الذين يبحثون عن ميزات تخصيص وخصوصية متقدمة غير متوفرة في واتساب الرسمي. يشمل ذلك التحكم المتقدم في رؤية المحادثات، تعديلات على المظهر، وإمكانية استخدام حسابات متعددة بكل سهولة.
                </p>

                <h2 class="h">
                  <span id="TBC_3">ميزات واتساب بلس</span>
                </h2>

                <div class="is-layout-flex wp-container-8 wp-block-columns">
                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">قفل التطبيق</h3>
                    <p>
                      يُحافظ على أمان التطبيق باستخدام رمز PIN أو بصمة الإصبع أو قفل النمط.
                    </p>

                    <h3 class="h3">قفل المحادثة</h3>
                    <p>
                      قفل المحادثات الفردية بكلمات مرور محددة.
                    </p>

                    <h3 class="h3">وضع التخفي</h3>
                    <p>
                      إخفاء حالة الاتصال بالإنترنت لتوفير الخصوصية.
                    </p>

                    <h3 class="h3">وضع عدم الإزعاج</h3>
                    <p>
                      تعطيل الإنترنت لواتساب بلس فقط دون التأثير على التطبيقات الأخرى.
                    </p>

                    <h3 class="h3">إرسال رسائل دون حفظ الرقم</h3>
                    <p>
                      إرسال رسائل إلى أرقام غير محفوظة مباشرة.
                    </p>

                    <h3 class="h3">إخفاء الكتابة/التسجيل</h3>
                    <p>
                      إخفاء مؤشر الكتابة أو التسجيل في المحادثات.
                    </p>

                    <h3 class="h3">تغيير الثيمات</h3>
                    <p>
                      الوصول إلى مكتبة واسعة من الثيمات للتخصيص.
                    </p>

                    <h3 class="h3">تغيير الخطوط</h3>
                    <p>
                      تعديل أنماط الخطوط بما يناسب تفضيلاتك.
                    </p>
                  </div>

                  <div class="is-layout-flow wp-block-column">
                    <h3 class="h3">إخفاء العلامات الزرقاء</h3>
                    <p>
                      قراءة الرسائل دون ظهور إشعارات القراءة.
                    </p>

                    <h3 class="h3">ترجمة الرسائل</h3>
                    <p>
                      ترجمة الرسائل في الوقت الفعلي إلى أي لغة مدعومة.
                    </p>

                    <h3 class="h3">الرسائل المحذوفة</h3>
                    <p>
                      الاحتفاظ بالرسائل التي حذفها المستخدمون الآخرون.
                    </p>

                    <h3 class="h3">الذهاب إلى أول رسالة</h3>
                    <p>
                      الانتقال إلى أول رسالة في أي محادثة بنقرة واحدة.
                    </p>

                    <h3 class="h3">جدولة الرسائل</h3>
                    <p>
                      جدولة الرسائل لإرسالها في أوقات محددة.
                    </p>

                    <h3 class="h3">الرد التلقائي</h3>
                    <p>
                      إعداد ردود تلقائية عند الانشغال.
                    </p>

                    <h3 class="h3">الرسائل غير المقروءة</h3>
                    <p>
                      تمييز الرسائل غير المقروءة في المحادثات.
                    </p>

                    <h3 class="h3">تنزيل الحالة</h3>
                    <p>
                      تنزيل الصور والفيديوهات وتحديثات الحالة النصية.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_4">الميزات المضافة حديثًا في 2024</span>
                </h2>
                <ul>
                  <li>وضع الشبح</li>
                  <li>تاريخ تعديل الرسائل</li>
                  <li>الذهاب إلى أول رسالة</li>
                  <li>واجهة المستخدم القديمة</li>
                  <li>وضع الليل/الوضع الفاتح</li>
                  <li>اختيار أعضاء إنشاء المجموعة</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_5">واتساب بلس ضد واتساب الرسمي</span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>الميزة الرئيسية</th>
                        <th>واتساب بلس</th>
                        <th>واتساب الرسمي</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>وضع الشبح</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>إخفاء الحالة على الإنترنت</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>قفل التطبيق</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>من يمكنه الاتصال بي</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>تغيير المواضيع</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>تغيير الخطوط</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>تغيير أيقونة العلامات</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>الرد التلقائي</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>جدولة الرسائل</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>الرسائل المضادة للمسح</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>الصور الأصلية</td>
                        <td>✅</td>
                        <td>❌</td>
                      </tr>
                      <tr>
                        <td>تجميد آخر ظهور</td>
                        <td>✅</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>خدمة العملاء</td>
                        <td>❌</td>
                        <td>✅</td>
                      </tr>
                      <tr>
                        <td>النظام</td>
                        <td>أندرويد</td>
                        <td>أندرويد، ويب، سطح المكتب، آي أو إس</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_6">نسخ احتياطي واستعادة WhatsApp Plus</span>
                </h2>

                <p>
                  يتيح واتساب الرسمي للمستخدمين عمل نسخة احتياطية لبياناتهم بترددات مختلفة إلى جوجل درايف مباشرة. ومع ذلك، فإن واتساب بلس هو نسخة معدلة ولا يمكنه الوصول إلى جوجل درايف.
                </p>
                <p>
                  يمكن لمستخدمي WhatsApp Plus الذهاب إلى مدير الملفات في هواتفهم الأندرويد، والعثور على موقع مجلد WhatsApp Plus. ثم يتم تحديد ملف قاعدة البيانات وتحميله إلى جوجل درايف.
                </p>
                <p>
                  إذًا، كيف يمكن استعادة البيانات إلى واتساب الرسمي؟ يمكن استبدال ملف قاعدة البيانات الرسمي بملف WhatsApp Plus. ثم ستعمل الأمور. لمزيد من التفاصيل، يمكنك قراءة مدوناتنا.
                </p>

                <h2 class="h">
                  <span id="TBC_7">متطلبات تطبيق WhatsApp Plus</span>
                </h2>
                <ul>
                  <li><strong>النظام: </strong>أندرويد 5.0 أو أعلى</li>
                  <li><strong>التخزين: </strong>على الأقل 200 ميجابايت من المساحة الفارغة</li>
                  <li><strong>لا يتطلب روت</strong></li>
                  <li><strong>رقم مسجل في WhatsApp</strong></li>
                </ul>

                <h2 class="h">
                  <span id="TBC_8"> كيفية تحميل WhatsApp Plus؟ </span>
                </h2>
                <ol>
                  <li>الخطوة 1: قم بتمكين <strong>تثبيت من مصادر غير معروفة</strong> ضمن الأذونات الخاصة بالتطبيقات.</li>
                  <li>الخطوة 2: قم بتحميل ملف APK لـ WhatsApp Plus من مصدر موثوق.</li>
                  <li>الخطوة 3: قم بتثبيت ملف APK على جهاز Android الخاص بك.</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_9"> كيفية تحديث WhatsApp Plus؟ </span>
                </h2>
                <p>
                  التحديثات تقوم بإصلاح الأعطال والأخطاء، وتعزز بروتوكولات الحماية ضد الحظر، وتضيف ميزات جديدة لتحسين الأداء. هنا يمكنك اختيار طريقتين لتحديث WhatsApp Plus.
                </p>
                <ul>
                  <li><strong>الطريقة 1: </strong>قم بتحميل أحدث نسخة من ملف APK من موقع موثوق وتثبيته للحفاظ على تطبيقك محدثًا.</li>
                  <li><strong>الطريقة 2: </strong>تحقق مما إذا كان التطبيق قد أخبرك بتحديث النسخة. يمكنك التحديث مباشرة.</li>
                </ul>

                <h2 class="h">
                  <span id="TBC_10"> الإصدار الرسمي 18.20 لـ WhatsApp Plus </span>
                </h2>
                <p>
                  الإصدار 18.20 هو الأحدث، ويوفر تخصيصًا معززًا، وخصوصية محسنة، وأداء محسن. تم تعزيز الحماية ضد الحظر وتم إصلاح بعض الأعطال.
                </p>
                <p>
                  تم إضافة دليل لربط WhatsApp Plus كجهاز مرفق لـ WhatsApp Web. يمكنك الاطلاع على الدليل لتسجيل الدخول إلى حسابك دون أن يتم حظرك.
                </p>

                <h2 class="h">
                  <span id="TBC_11"> WhatsApp Plus 2025 القادم </span>
                </h2>
                <p>
                  يتوقع المستخدمون أن يقوم المطورون بإزالة القيود على استخدام حساب WhatsApp Plus 2025 التي فرضتها النسخة الرسمية من WhatsApp.
                </p>

                <h2 class="h">
                  <span id="TBC_12"> الإصدارات القديمة من WhatsApp Plus </span>
                </h2>
                <p>
                  تشمل الإصدارات السابقة البارزة v18.10 و v18.00 و v17.85، التي قدمت ميزات وتحديثات هامة.
                </p>

                <h2 class="h">
                  <span id="TBC_13"> WhatsApp Plus لـ iPhone </span>
                </h2>
                <p>
                  حاليًا، WhatsApp Plus غير متاح على iOS بسبب قيود متجر التطبيقات. لذلك، يجب على المستخدمين استخدام تطبيق WhatsApp Plus على Android.
                </p>

                <h2 class="h">
                  <span id="TBC_14"> تحميل WhatsApp Plus للكمبيوتر/اللابتوب </span>
                </h2>
                <p>
                  نظرًا لأن WhatsApp Plus غير متاح على iPhone، يجد المستخدمون الذكيون طريقة غير مباشرة لتثبيت ملف APK لـ WhatsApp Plus على الكمبيوتر (Windows 7 و 8 و 10 و 11).
                </p>
                <p>
                  استخدام Bluestacks أو أي محاكي Android عالي الجودة يمكن أن يساعدك في تثبيت WhatsApp Plus على الكمبيوتر. لتثبيت التطبيق بنجاح، يجب عليك قراءة دليل المستخدم الخاص بـ Bluestacks أولاً.
                </p>

                <h2 class="h">
                  <span id="TBC_15"> مزايا وعيوب WhatsApp Plus </span>
                </h2>
                <figure class="wp-block-table">
                  <table>
                    <thead>
                      <tr>
                        <th>المزايا</th>
                        <th>العيوب</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>ميزات مخصصة لتعزيز تجربة الرسائل</td>
                        <td>غير مدعوم رسميًا، قد تكون هناك مخاطر أمنية، مخاطر حظر الحساب.</td>
                      </tr>
                      <tr>
                        <td>خيارات خصوصية أكثر لحماية خصوصية المستخدمين</td>
                        <td>مخاطر حظر الحساب</td>
                      </tr>
                      <tr>
                        <td>مجانًا لتحميل وتثبيت التطبيق. لا توجد رسوم خفية.</td>
                        <td>وضع المرافق قد يكون محدودًا من قبل النسخة الرسمية</td>
                      </tr>
                    </tbody>
                  </table>
                </figure>

                <h2 class="h">
                  <span id="TBC_16"> تقييمات المستخدمين </span>
                </h2>
                <ol>
                  <li><strong>أليس: </strong>"رائع للتخصيص، استمتع جدًا بالسمات!"</li>
                  <li><strong>جون: </strong>"أخيرًا وجدت طريقة للتحكم في من يراني على الإنترنت"</li>
                </ol>

                <h2 class="h">
                  <span id="TBC_17"> الأسئلة الشائعة </span>
                </h2>
                <div class="schema-faq wp-block-yoast-faq-block">
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">هل من الآمن استخدام WhatsApp Plus؟</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      هو غير رسمي وقد يحمل مخاطر أمنية، لكنه يُستخدم على نطاق واسع في جميع أنحاء العالم.
                    </p>
                  </div>
                  <div class="schema-faq-section">
                    <strong class="schema-faq-question faq-q-open">هل يمكنني استخدام WhatsApp Plus و WhatsApp الرسمي على نفس الجهاز؟</strong>
                    <p class="schema-faq-answer faq-q-open" style="display: block">
                      نعم، مع دعم التطبيقات المزدوجة على أجهزة أندرويد.
                    </p>
                  </div>
                </div>

                <h2 class="h">
                  <span id="TBC_18"> الكلمات الأخيرة </span>
                </h2>
                <p>
                  يعد WhatsApp Plus خيارًا جذابًا للمستخدمين الذين يبحثون عن تجربة مراسلة أكثر تخصيصًا، ولكن يجب على المستخدمين النظر في المخاطر الأمنية المحتملة.
                </p>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="snippet-box">
    <div class="snippet-title">
      الملخص
    </div>

    <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
      <div class="aio-info">
        <div class="snippet-label-img snippet-clear">
          تقييم المؤلف
        </div>
        <div class="snippet-data-img">
          <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"></span>
        </div>
        <div class="snippet-clear" />
        <div class="aggregate_sec" itemprop="aggregateRating" itemscope="" itemtype="https://schema.org/AggregateRating">
          <div class="snippet-label-img">
            التقييم الإجمالي
          </div>
          <div class="snippet-data-img">
            <span itemprop="ratingValue">3.65</span> بناءً على
            <span class="rating-count" itemprop="ratingCount">46258</span>
            تصويت
          </div>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          اسم البرنامج
        </div>
        <div class="snippet-data-img">
          <span itemprop="name">GBWhatsApp APK</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          نظام التشغيل
        </div>
        <div class="snippet-data-img">
          <span itemprop="operatingSystem">أندرويد</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          فئة البرنامج
        </div>
        <div class="snippet-data-img">
          <span itemprop="applicationCategory">تطبيق</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          السعر
        </div>
        <div class="snippet-data-img">
          <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
            <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          صفحة الهبوط
        </div>
        <div class="snippet-data-img">
          <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
        </div>
        <div class="snippet-clear" />
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="/privacy">سياسة الخصوصية</a>
        </div>
        <div class="copyright-bar">
          2025 © جميع الحقوق محفوظة <strong>
            <router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link>
          </strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="goto('download')">
    تحميل GBWhatsApp الجديد
  </div>

</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'ar',
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'en-download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatapks.com',
          appName: 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
